import React, { useState } from 'react'
import Loop_file from '../../../../pages/file/Loop_file'

import './styles.scss'
import UploadFile from '../../../../pages/file/Upload_file'
import { GQL_SECTION, MUTATION_UPDATESECTION } from '../../../../shared/Services/Graphql/Graphql'
import { useMutation } from '@apollo/client'

export const ModalFilesBox = ({ setActive, id, title }) => {
    const [selectFile, setSelectFile] = useState({
        id: '', url: '', name: '', width: 0, height: 0
    })

    const [updateSection] = useMutation(MUTATION_UPDATESECTION, {
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: GQL_SECTION, variables: { id: id } },
        ],
    })

    const handleImageSelect = () => {
        updateSection({
            variables: {
                id: id,
                data: {
                    cover: selectFile.id,
                },
            },
        })
            .then((res) => {
                console.log(res.data)
                setActive('')
            })
            .catch((err) => {
                console.log(err)
            })
    };

    return (
        <div className="modal_files">
            <div className="modal_inner">
                <div className='modal_header'>
                    <h3>{title}</h3>
                    <button onClick={() => setActive('')}>Close</button>
                </div>
                <div className='modal_body'>
                    <div className='modal_upload'>
                        <UploadFile />
                    </div>
                    <div className='modal_loop'>
                        <Loop_file selectFile={setSelectFile} select={selectFile.id} />
                    </div>
                </div>
                <div className='modal_footer'>
                    <button onClick={() => handleImageSelect()}>Select</button>
                </div>
            </div>
        </div>
    )
}
