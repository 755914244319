import { useNavigate } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import { useRef, useState, ChangeEvent } from 'react'
import { MUTATION_CREATENEWBRAND } from '../../../shared/Services/Graphql/Graphql'

import './new_brand.scss'

interface FormData {
  name: string
  slug: string
}

export default function NewBrand() {
  const [isURL, setURL] = useState<string | null>(null)

  const form = useRef<HTMLDivElement>(null)

  let navigate = useNavigate()

  const [createBrand] = useMutation<{ createBrand: { id: string } }>(
    MUTATION_CREATENEWBRAND,
  )

  function createNewBrand() {
    const nameBrand = (document.querySelector('.nameBrand') as HTMLInputElement)
      .value

    const formData: FormData = {
      name: nameBrand,
      slug: nameBrand
        .toLowerCase()
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .replace(/ /g, '-')
        .replace(/[^\w-]+/g, ''),
    }

    createBrand({
      variables: { data: formData },
    })
      .then((res) => {
        if (res.data?.createBrand?.id) {
          console.log(res.data)
          navigate(`/brand/${res.data.createBrand.id}`)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  function handleInputChange(e: ChangeEvent<HTMLInputElement>) {
    const slug = document.querySelector('.slugBrand') as HTMLInputElement
    slug.value = e.target.value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/ /g, '_')
      .replace(/[^\w_]+/g, '')
  }

  return (
    <>
      <section className="new_brand">
        <div className="container">
          <div className="adicionar_new_brand">
            <div className="form" ref={form}>
              <div className="field width-flex-50">
                <label htmlFor="nameBrand">Nome da Marca</label>
                <input
                  type="text"
                  className="nameBrand"
                  id="nameBrand"
                  name="nameBrand"
                  onChange={handleInputChange}
                />
              </div>
              <div className="field width-flex-50">
                <label htmlFor="slugBrand">Slug</label>
                <input
                  type="text"
                  className="slugBrand"
                  id="slugBrand"
                  name="slugBrand"
                />
              </div>
              <div className="field width-flex-100">
                <label htmlFor="descriptionBrand">Descrição da Marca</label>
                <input
                  type="text"
                  className="descriptionBrand"
                  id="descriptionBrand"
                  name="descriptionBrand"
                />
              </div>
              <div className="button">
                <button type="button" onClick={createNewBrand}>
                  SALVAR E AVANÇAR
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
