export const imagemScale = (path: string) => {
  const img = document.createElement('img')

  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      const width: number = img.naturalWidth
      const height: number = img.naturalHeight

      resolve({width, height})
    }
    img.onerror = reject
  })

  img.src = 'https://files.universodamarca.com.br/' + path
  return promise
}
