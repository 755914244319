import { useState } from "react";
import { useParams } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Plus_icon } from "../../../../assets/svg";
import { GQL_MODULE, MUTATION_UPDATEMODULE } from "../../../shared/Services/Graphql/Graphql";
import { ContentModule } from "../../module/content";
import { DefinedBlock } from "./defined_block";

export const MoreBlocks = ({ position, options, typeBlock }: {
  position?: string,
  options?: string,
  typeBlock: string,
}) => {
  const { module } = useParams();

  const dates = ContentModule();

  const [UpdateModule] = useMutation(MUTATION_UPDATEMODULE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_MODULE, variables: { id: module } }],
  });

  const updateSection = (data) => {
    UpdateModule({
      variables: {
        id: module,
        data: {
          content: JSON.stringify(data),
        },
      },
    })
      .then((res) => console.log(res))
      .catch((err) => console.log(err));
  };

  const addMoreBlock = (content, newBlock, targetId, position) => {
    let newContent = [...content];

    for (let i = 0; i < newContent.length; i++) {
      if (newContent[i].id === targetId) {
        if (position === 'before') {
          newContent.splice(i, 0, newBlock);
        } else if (position === 'after') {
          newContent.splice(i + 1, 0, newBlock);
        } else {
          newContent[i].content.push(newBlock);
        }
        return newContent;
      } else if (newContent[i].content && Array.isArray(newContent[i].content)) {
        newContent[i].content = addMoreBlock(newContent[i].content, newBlock, targetId, position);
      }
    }
    return newContent;
  };

  const handleMoreBlockClick = (e, type) => {
    const id = e.target.closest('.edit_block')?.dataset.id;

    if (e) {
      const newBlock = {
        id: `block_${Math.random().toString(36).substr(2, 9)}`,
        name: type.name,
        className: type.class.value,
        attributes: Object.keys(type.attributes).reduce((acc, key) => {
          acc[key] = type.attributes[key].value;
          return acc;
        }, {}),
        style: type.style.value,
        content: type.content.value,
      };

      const updatedData = !dates || dates.length <= 0 ? [newBlock] : addMoreBlock(dates, newBlock, id, position);
      updateSection(updatedData)
    }
  };

  return (
    <div className={`${position}_more_block`}>
      {(options === position || position === "inner") &&
        <DefinedBlock
          typeBlock={typeBlock}
          position={position}
          handleMoreBlockClick={handleMoreBlockClick}
        />
      }
      {position !== "inner" && <Plus_icon />}
    </div>
  );
};
