import { useState } from 'react'
import { Avatar_Icon } from '../../../assets/svg'
import { UserModel } from '../../shared/interfaces/UserModel'
import './cards_user.scss'
import { Link } from 'react-router-dom'

interface Props {
  user: UserModel
  idUrl: any
}

export const Cards_User = ({ user, idUrl }: Props) => {
  return (
    <Link
      to={{ pathname: '/users', search: `?id=${user.id}` }}
    >
      <div className={`card_user ${idUrl === user.id ? 'select_card' : ''}`}>
        <div
          className="user_content"
        >
          <div className="user_avatar">{Avatar_Icon}</div>
          <p className="user_name"> {user.name ? user.name : user.email}</p>
        </div>
      </div>
    </Link>
  )
}
