import { useMutation, useQuery } from "@apollo/client";
import { Item_line_icon, Plus_icon } from "../../../../assets/svg";
import {
  GQL_SECTION,
  MUTATION_CREATESUBSECTION,
} from "../../../shared/Services/Graphql/Graphql";
import MenuItem from "./menu_brand_item";

const SubmenuBrand = ({ sectionData, idBrand, slug }: { sectionData: any, idBrand: string, slug: string }) => {
  const { error, loading, data } = useQuery(GQL_SECTION, {
    variables: { id: sectionData.id },
    fetchPolicy: "network-only",
  });

  const [createSubsection] = useMutation(MUTATION_CREATESUBSECTION, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_SECTION, variables: { id: sectionData.id } }],
  });

  function createNewSubsection() {
    createSubsection({
      variables: {
        id: sectionData.id,
        idBrand: idBrand,
        data: {
          name: "Nova Subseção",
        },
      },
    })
      .then((res) => {
        if (res.data) {
          console.log(res.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Alguma coisa está errada</p>;

  return (
    <>
      <div className='submenu_brand'>
        <ul className="submenu_brand_items">
          {data?.section.subsections?.map((subsection: any) => (
            <li
              className='submenu_brand_item selected_item'
              key={subsection.id}
            >
              <Item_line_icon />
              <MenuItem
                sectionData={subsection}
                slug={slug}
                sectionParent={sectionData.id}
              ></MenuItem>
            </li>
          ))}
          <li className='submenu_brand_add' onClick={createNewSubsection}>
            <Plus_icon />
            <p>Adicionar subseção </p>
          </li>
        </ul>
      </div>
    </>
  );
};
export default SubmenuBrand;
