import { useMutation } from '@apollo/client'
import { Delete_icon } from '../../../assets/svg'
import {
  GQL_USER,
  MUTATION_REMOVEBRANDTOUSER,
} from '../../shared/Services/Graphql/Graphql'

export const List_brand_user = (props: any) => {
  const { id, brands } = props

  const [selectBrand] = useMutation(MUTATION_REMOVEBRANDTOUSER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_USER, variables: { id: id } }],
  })

  function select(idBrand: string) {
    selectBrand({
      variables: {
        id: id,
        idBrand: idBrand,
      },
    })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
    {
    }
  }

  return (
    <div className="list_brands_user">
      {brands.map((b: any, i: number) => (
        <div className="item_brands_user" key={i}>
          <div className="info_brand_user">
            <div className="logo_brand_user">
              <img src={b.logo?.url} alt={b.logo?.title} />
            </div>
            <div className="nome_brand_user">{b.name}</div>
          </div>
          <div className="options_brand_user">
            <div className="option_brand_user">
              <div className="remove" onClick={() => select(b.id)}>
                <Delete_icon />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}
