import React from "react";
import EditableContent from "../../editableContent";

const Paragraph = ({ attributes, content, className, style}) => {

  return React.createElement(
    "p",
    {
      className: className,
      style: style,
    },
    <EditableContent
      content={content}
      className={className}
    />
  );
};

export default Paragraph;
