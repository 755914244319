import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import Dashboard from '../../pages/dashboard/Dashboard'
import Brand from '../../components/Brand/Brand'
import Brands from '../../components/Brand/List/ListBrands'
import UploadFile from '../../components/UploadFile/UploadFile'
import Item from '../../pages/item/item'
import { AuthProvider } from '../Auth/Auth'
import { ProtectedLayout } from '../../components/Protected/Protected'
import Login from '../../pages/login'
import Users from '../../components/User/List/ListUsers'
import NewUser from '../../components/User/Create/NewUser'
import FillUser from '../../components/FillUser/FillUser'
import ForgotPassword from '../../components/ForgotPassword/ForgotPassword'
import ForgotPasswordMessage from '../../widgets/ForgotPasswordMessage/ForgotPasswordMessage'
import ChangePassword from '../../components/ChangePassword/ChangePassword'
import NotFoundPage from '../../widgets/NotFoundPage/NotFoundPage'
import Files from '../../pages/file/Files'
import { Model } from '../../pages/models/model'

export const AppRoute = () => {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<NotFoundPage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route
            path="/forgot-password-message"
            element={<ForgotPasswordMessage />}
          />
          <Route
            path="/user/change-password/:id"
            element={<ChangePassword />}
          />
          <Route path="/user/fill-user/:id" element={<FillUser />} />
          <Route
            path="/"
            element={
              <ProtectedLayout local="">
                <Navigate to="/dashboard" replace />
              </ProtectedLayout>
            }
          />
          <Route
            path="/dashboard"
            element={
              <ProtectedLayout local="Dashboard">
                <Dashboard />
              </ProtectedLayout>
            }
          />
          <Route
            path="/brands"
            element={
              <ProtectedLayout local="Brands">
                <Brands />
              </ProtectedLayout>
            }
          />
          <Route
            path="/:brand/:id"
            element={
              <ProtectedLayout local={'Dashboard'}>
                <Brand />
              </ProtectedLayout>
            }
          />
          <Route
            path="/users"
            element={
              <ProtectedLayout local="Dashboard">
                <Users />
              </ProtectedLayout>
            }
          />
          <Route
            path="/user/:id"
            element={
              <ProtectedLayout local="Dashboard">
                <Users />
              </ProtectedLayout>
            }
          />
          <Route
            path="/user/new"
            element={
              <ProtectedLayout local="Dashboard">
                <NewUser />
              </ProtectedLayout>
            }
          />
          <Route
            path="/upload"
            element={
              <ProtectedLayout local="Dashboard">
                <UploadFile />
              </ProtectedLayout>
            }
          />
          <Route
            path="/:brand/:section/:module"
            element={
              <ProtectedLayout local="Dashboard">
                <Item />
              </ProtectedLayout>
            }
          />
          <Route
            path="/files"
            element={
              <ProtectedLayout local="Dashboard">
                <Files />
              </ProtectedLayout>
            }
          />
          <Route
            path="/model/:model/:section/:module"
            element={
              <ProtectedLayout local={'Dashboard'}>
                <Model />
              </ProtectedLayout>
            }
          />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  )
}
