import { gql } from "@apollo/client";

export const GQL_MODELS = gql`
  query Models {
    models {
      id
      name
      sections {
        id
        name
        slug
        cover {
          id
        }
        modules {
          id
          name
          content
        }
        subsections {
          id
          name
          cover {
            id
          }
          modules {
            id
            name
            content
          }
        }
      }
    }
  }
`;

export const GQL_MODEL = gql`
  query Model($id: ID!) {
    model(id: $id) {
      id
      name
      sections {
        id
        name
        slug
        cover {
          id
        }
        modules {
          id
          name
          content
        }
        subsections {
          id
          name
          cover {
            id
          }
          modules {
            id
            name
            content
          }
        }
      }
    }
  }
`;
