import React, { useState } from 'react';
import { Delete_icon, Image_icon } from '../../../../assets/svg'; // Ajuste o caminho conforme necessário
import { ModalFilesBrand } from '../modal_files_brand'; // Ajuste o caminho conforme necessário
import { GQL_BRAND, MUTATION_UPDATESETTINGSBRAND } from '../../../shared/Services/Graphql/Graphql';
import { useMutation } from '@apollo/client';
import { ImageBox } from '../../blocks/types/box_children/image_box';

interface DefinedImageType {
    id: string;
    url: string;
    name: string;
    width?: number;
    height?: number;
}

export const DownloadCover = ({ idBrand, settings }) => {
    const [activeModal, setActiveModal] = useState(false);
    const [definedImage, setDefinedImage] = useState<DefinedImageType | null>(null);

    const [updateCover] = useMutation(MUTATION_UPDATESETTINGSBRAND, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GQL_BRAND, variables: { id: idBrand } }],
    });

    function update_cover(idFile: string, remove?: boolean) {
        updateCover({
            variables: {
                id: idBrand,
                data: {
                    downloadImage: remove ? null : idFile,
                },
            },
        })
            .then((res) => {
                console.log(res);
                activeModal && setActiveModal(false);
                definedImage && setDefinedImage(null);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    return (
        <>
            <div className='highlights_config'>
                <h4 className='highlights_title'>Capa download</h4>
                <div className='highlights_remove' onClick={() => update_cover(settings?.downloadImage?.id, true)}>
                    <Delete_icon width='24px' />
                </div>
            </div>
            <div className='form_action_image'>
                <div className="form_action_image" onClick={() => setActiveModal(true)}>
                    {
                        settings?.downloadImage?.id ?
                            <ImageBox id={settings.downloadImage?.id} /> :
                            definedImage ? (
                                <img src={definedImage.url} alt={definedImage.name} />
                            ) : (
                                <div className="select">
                                    <Image_icon width="48px" />
                                    <p>Selecionar arquivo</p>
                                </div>
                            )}
                </div>
            </div>
            {activeModal && (
                <ModalFilesBrand
                    setActiveModal={setActiveModal}
                    setDefinedImage={setDefinedImage}
                    update={update_cover}
                    title="Download Capa"
                />
            )}
        </>
    );
};
