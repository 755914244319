import { Delete_brand } from './delete_brand'
import { InputUpdateBrand } from './input_update_brand'
import { ControlerUser } from './controler_user'
import { HighlightsSections } from './highlights_sections'
import { Logos } from './logos'

import './config_brand.scss'
import { Files } from './files'
import { SelectImageHigh } from './select_image_high'
import { Colors } from './colors'
import { ModelBrand } from './model_brand'

export const Config_brand = (props: any) => {
  const { data } = props
  return (
    <div className="menu_config">
      <div className='first_config'>
        <h3 className='title_section'>Informações Brand</h3>
        <div className="name_config">
          <div className='field'>
            <label htmlFor="name" >nome</label>
            <input id="name" value={data.name} />
          </div>
        </div>
        <div className="slug_config">
          <div className='field'>
            <label htmlFor="slug" >slug</label>
            <input id="slug" value={data.slug} />
          </div>
        </div>
        <div className="phrase_config">
          <div className='field'>
            <label htmlFor="phrase" >phrase</label>
            <InputUpdateBrand type="text" item='phrase' id={data.id} value={data.phrase} />
          </div>
        </div>
        <div className="logo_login">
          <div className='field'>
            <SelectImageHigh idBrand={data.id} logos={data.logos} settings={data.settings} local='logoLogin' title="Logo Login" />
          </div>
        </div>
        <div className="logo_login">
          <div className='field'>
            <SelectImageHigh idBrand={data.id} logos={data.logos} settings={data.settings} local='logoHightlight' title="Logo Interna" />
          </div>
        </div>
      </div>
      <Logos idBrand={data?.id} logos={data?.logos} />
      <Files idBrand={data?.id} files={data?.files} />
      <HighlightsSections
        idBrand={data?.id}
        sections={data?.sections}
        highlights={data?.highlights}
        settings={data?.settings}
      />
      <Colors idBrand={data?.id} initialColors={data?.settings} />
      {/* <div className='colors_config'>
        <h3 className='title_section'>Paleta cromática</h3>
        <div className='palette'>
          <p className='title_colors'>primárias</p>
          <div className='colors'>
            <div className='color' style={{ background: '#255fff' }}></div>
            <div className='color' style={{ background: '#062f91' }}></div>
            <div className='color' style={{ background: '#a5a5a5' }}></div>
            <div className='color' style={{ background: '#e6e6e6' }}></div>
            <div className='more_color'>+</div>
          </div>
        </div>
        <div className='palette'>
          <p className='title_colors'>secundárias</p>
          <div className='colors'>
            <div className='color' style={{ background: '#ffd506' }}></div>
            <div className='color' style={{ background: '#00ffff' }}></div>
            <div className='color' style={{ background: '#cf128c' }}></div>
            <div className='color' style={{ background: '#ffffff' }}></div>
            <div className='more_color'>+</div>
          </div>
        </div>
      </div> */}
      <div className='select_user'>
        <h3 className='title_section'>Usuários</h3>
        <ControlerUser idBrand={data.id} />
      </div>
      <div className='details'>
        <div className="id_config"><p>id: {data.id}</p></div>
        <div className="dates">
          {data.update_at ? (
            <>
              <div className="create_at_config">
                <p>{data.created_at && new Date(data.created_at).toLocaleString()}</p>
              </div>
              <div className="created_by_config"><p>{data.created_by?.name}</p></div>
            </>
          ) : (
            <>
              <div className="create_at_config">
                <p> {data.update_at && new Date(data.update_at).toLocaleString()}</p>
              </div>
              <div className="updated_by_config"><p>{data.update_by?.name}</p></div>
            </>
          )}
        </div>
      </div>
      <div className="actions_brand">
        <ModelBrand id={data.id} name={data.name} />
        <Delete_brand id={data.id} redirect={true} />
      </div>
    </div>
  )
}
