import { useNavigate } from "react-router-dom";
import { Not_Found_Image } from "../../../assets/svg";

import "./NotFoundPage.scss";

export default function NotFoundPage() {
  const navigate = useNavigate();

  const redirectToDashboard = () => {
    navigate("/dashboard");
  };
  return (
    <section className="not_found_page">
      <div className="container">
        <div className="imagemsvg">{Not_Found_Image}</div>

        <h2>Ops! Nem tudo está indo como planejado.</h2>

        <span>Tivemos dificuldade em encontrar a Página solicitada.</span>

        <a onClick={redirectToDashboard}>Voltar para a Página Inicial</a>
      </div>
    </section>
  );
}
