import { Link } from 'react-router-dom'
import { Information_icon } from '../../../assets/svg'

export const CardsModels = ({ model }) => {
    return (
        <div className="card_brand">
            <Link
                to={{
                    pathname: `/model/${model?.id}/${model?.sections[0]?.id}/${model?.sections[0]?.modules[0]?.id}`,
                }}
            >
                <div className="topo_card_brand">
                    <div className="nome_brand">
                        <p>{model.name}</p>
                    </div>
                    <div
                        className="information_brand"
                    >
                        {Information_icon}
                    </div>
                </div>
            </Link>
        </div>
    )
}
