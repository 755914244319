import { useRef } from 'react'
import { Link } from 'react-router-dom'

import { Information_icon } from '../../../assets/svg'

import './cards_brands.scss'
import { BrandModel } from '../../shared/interfaces/BrandModel'

interface Props {
  brand: BrandModel
}

export interface Logo {
  type: string;
  file?: {
    url: string;
  };
}

const Cards_Brands = ({ brand }: Props) => {
  const box_information_ref = useRef<HTMLDivElement>(null)

  const logo = brand?.logos?.find((l: Logo) => l.type === 'PRIMARY') as Logo | undefined;

  return (
    <div className="card_brand">
      <Link
        to={{
          pathname: `/${brand?.slug}/${brand?.id}`,
        }}
      >
        <div className="topo_card_brand">
          <div className="nome_brand">
            <p>{brand.name}</p>
          </div>
          <div
            className="information_brand"
            onMouseOver={() => {
              box_information_ref.current?.classList.add('show')
            }}
            onMouseOut={() => {
              box_information_ref.current?.classList.remove('show')
            }}
          >
            {Information_icon}
          </div>
        </div>
        <div className="imagem_brand">
          {logo ? (
            <img
              src={logo.file?.url}
              title={brand.name}
              alt={brand.name}
            />
          ) : (
            <div className="letter_first_brand">
              {brand.name.split(' ').map((name, index) => (
                <h2 key={index}>{name.charAt(0).toUpperCase()}</h2>
              ))}
            </div>
          )}
        </div>
        <div
          className="box_information_brand"
          ref={box_information_ref}
          onMouseOver={(e) => {
            box_information_ref.current?.classList.add('show')
          }}
          onMouseOut={(e) => {
            box_information_ref.current?.classList.remove('show')
          }}
        >
          <div className="text_box_information_brand">
            <p className="name_box_information_brand">
              <b>Nome:</b> {brand.name}
            </p>
            <p className="person_box_information_brand">
              <b>Responsável: {brand.id}</b>
            </p>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default Cards_Brands
