import React, { useState } from 'react';
import UploadFile from '../../pages/file/Upload_file';
import Loop_file from '../../pages/file/Loop_file';

import './config_brand.scss';

export const ModalFilesBrand = (
    { setActiveModal, setDefinedImage, update, title }:
        { setActiveModal: any, setDefinedImage: any, update?: any, title: string }) => {
    const [selectFile, setSelectFile] = useState({
        id: '', url: '', name: '', width: 0, height: 0
    });

    // Função para lidar com a seleção do arquivo
    const handleSelectClick = () => {
        if (selectFile.id) {
            setDefinedImage(selectFile); // Passa o objeto selectFile inteiro
            setActiveModal(false);
            
            update && console.log(update(selectFile.id));
        } else {
            alert("Por favor, selecione um arquivo antes de clicar em 'Select'.");
        }
    };

    return (
        <div className="modal_files">
            <div className="modal_inner">
                <div className='modal_header'>
                    <h3>{title}</h3>
                    <button onClick={() => setActiveModal(false)}>Close</button>
                </div>
                <div className='modal_body'>
                    <div className='modal_upload'>
                        <UploadFile />
                    </div>
                    <div className='modal_loop'>
                        <Loop_file selectFile={setSelectFile} select={selectFile.id} />
                    </div>
                </div>
                <div className='modal_footer'>
                    <button onClick={handleSelectClick}>Select</button>
                </div>
            </div>
        </div>
    );
};
