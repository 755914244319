import { useQuery } from '@apollo/client'
import { GQL_FILE } from '../../shared/Services/Graphql/Graphql'
import { useSearchParams } from 'react-router-dom'
import Delete_file from './Delete_file'

export default function Info_file(props: any) {
  const { searchParams } = props

  const { loading, error, data } = useQuery(GQL_FILE, {
    variables: { id: searchParams.get('id') },
  })

  if (loading) return <div className="content_information_file"></div>
  if (error) return <div className="content_information_file"></div>

  return (
    <>
      <div className="header_information_file">
        <div className="figure_info_file">
          <figure>
            <img src={data.file.url} alt="file" />
          </figure>
        </div>
        <div className="content_info_file">
          <div className="name_info_file">{data.file.name}</div>
          <div className="outhers_info_file">
            <p>{(data.file.size / (1024 * 1024)).toFixed(2)} MB</p>
            <p>{data.file.type}</p>
          </div>
        </div>
      </div>
      <div className="body_information_file">
        <div className="title_info_file">
          <h4>Informações</h4>
        </div>
        <div className="content_info_file">
          <div className="item_info_file">
            <h4 className="title">URL</h4>
            <p>{data.file.url}</p>
          </div>
          <div className="item_info_file">
            <h4 className="title">Alt</h4>
            <p>{data.file.alt}</p>
          </div>
          <div className="item_info_file">
            <h4 className="title">Tipo</h4>
            <p>{data.file.type}</p>
          </div>
          <div className="item_info_file">
            <h4 className="title">Data</h4>
            <p>{new Date(data.file.created_at).toLocaleString()}</p>
          </div>
          <div className="item_info_file">
            <h4 className="title">Tamanho</h4>
            <p>
              {data.file.width} x {data.file.height}
            </p>
          </div>
        </div>
        <div className="actions_file">
          <Delete_file url={data.file.url} id={data.file.id} />
        </div>
      </div>
    </>
  )
}
