import {
  Admin_Icon,
  Editor_Icon,
  Inativo_Icon,
  Responsavel_Icon,
  Temporario_Icon,
  Visualizador_Icon,
} from '../../../../assets/svg'

export const NivelUser = [
  {
    name: 'Admin',
    slug: 'admin',
    icon: Admin_Icon,
  },
  {
    name: 'Editor',
    slug: 'editor',
    icon: Editor_Icon,
  },
  {
    name: 'Responsável',
    slug: 'responsavel',
    icon: Responsavel_Icon,
  },
  {
    name: 'Visualizador',
    slug: 'visualizador',
    icon: Visualizador_Icon,
  },
  {
    name: 'Temporário ',
    slug: 'temporario',
    icon: Temporario_Icon,
  },
  {
    name: 'Inativo ',
    slug: 'inativo',
    icon: Inativo_Icon,
  },
]
