import { useNavigate } from "react-router-dom";
import { useAuth } from "../../shared/Auth/UseAuth";
import { Enredo } from "../../../assets/svg";

import "./index.scss";
import { useRef, useState } from "react";

export default function Login() {
  const auth = useAuth();
  const navigate = useNavigate();

  const userName = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);

  const [isAuth, setAuth] = useState();

  async function login(u: any, p: any, ia: any, sa: any, n: any) {
    auth.authenticate(u, p, ia, sa, n);
  }

  function forgotPassword() {
    navigate("/forgot-password");
  }

  return (
    <section className='login'>
      <div className='access'>
        <div className='login_access'>
          <div className="name">
            <h2>BRAND</h2>
            <h2>UNIVERSE</h2>
          </div>
          <div className="form">
            <div className='field'>
              <div className="field_top">
                <label htmlFor='userName'>
                  User / E-mail
                </label>
              </div>
              <input
                type='text'
                className={`userName ${!isAuth ? "" : "error"}`}
                id='userName'
                name='userName'
                ref={userName}
                onKeyDown={(e) => e.key === "Enter" && login(
                  userName.current?.value,
                  password.current?.value,
                  isAuth,
                  setAuth,
                  navigate
                )}
              />
            </div>
            <div className='field'>
              <div className="field_top">
                <label htmlFor='password'>Senha</label>
              </div>
              <input
                type='password'
                className={`password ${!isAuth ? "" : "error"}`}
                id='password'
                name='password'
                ref={password}
                onKeyDown={(e) => e.key === "Enter" && login(
                  userName.current?.value,
                  password.current?.value,
                  isAuth,
                  setAuth,
                  navigate
                )}
              />
            </div>
            {/* <div className='checkbox remember'>
              <div className='box'>
                <input type='checkbox' id='remember' />
              </div>
              <label htmlFor='remember'>Lembre-se de mim</label>
            </div> */}
            <div className="buttons">
              <button
                type='button'
                onClick={() =>
                  login(
                    userName.current?.value,
                    password.current?.value,
                    isAuth,
                    setAuth,
                    navigate
                  )
                }
              >
                Entrar
              </button>
              <p className='error_mensage'>{isAuth}</p>
              <div className='reset'>
                <a className='simple-button' onClick={forgotPassword}>
                  Esqueceu sua senha?
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className='footer'>
          {/* {Enredo} */}
          <p>© Enredo 2023 - Brand Innovation</p>
        </div>
      </div>
      <div className='imagem'>
        <div className='background'>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </section>
  );
}
