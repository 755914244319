import { useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { GQL_BRAND, MUTATION_UPDATEFILEBRAND } from '../../../shared/Services/Graphql/Graphql';

export const UpdateFileName = ({ file, idBrand }) => {
    const [name, setName] = useState(file.type);
    const [isEditable, setIsEditable] = useState(true);

    const [updateFileName] = useMutation(MUTATION_UPDATEFILEBRAND, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GQL_BRAND, variables: { id: idBrand } }],
    });

    useEffect(() => {
        if (file.type === 'BACKGROUND' || file.type === 'COVER') {
            setIsEditable(false);
        }
    }, [file.type]);

    function update_file_name(updatedName: string) {
        const trimmedName = updatedName.trim();
        if (trimmedName && isEditable) {
            updateFileName({
                variables: {
                    id: file.id,
                    data: {
                        type: trimmedName,
                    },
                },
            })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <input
            type="text"
            className='file_name'
            value={name}
            onChange={(e) => setName(e.target.value.replace(/\s/g, '').toLocaleUpperCase())}
            onBlur={() => update_file_name(name)}
            disabled={!isEditable}
        />
    );
};
