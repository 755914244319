import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { GQL_SECTION, MUTATION_CREATEDOWNLOAD } from '../../../shared/Services/Graphql/Graphql';
import { Plus_icon } from '../../../../assets/svg';

export const AddDownload = ({ section }) => {
    const [add, setAdd] = useState(false);
    const [infos, setInfos] = useState({
        name: '',
        link: ''
    });

    const [createDownload] = useMutation(MUTATION_CREATEDOWNLOAD, {
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: GQL_SECTION, variables: { id: section } },
        ],
        onCompleted: () => setAdd(false), // Fecha o formulário após a criação
    });

    const handleDownload = () => {
        if (infos.name && infos.link) { // Verifica se ambos os campos estão preenchidos
            createDownload({
                variables: {
                    id: section,
                    data: infos,
                }
            })
                .then((res) => {
                    console.log(res.data);
                    setInfos({ name: '', link: '' }); // Limpa o formulário após a adição
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const isButtonDisabled = !infos.name || !infos.link; // Desabilita o botão se algum dos campos estiver vazio

    return (
        <li className='download_add'>
            <div className='download_add_button' onClick={() => setAdd(!add)}>
                <Plus_icon />
                <p>Adicionar downloads</p>
            </div>
            {add &&
                <div className='downloads_add_form'>
                    <input
                        type="text"
                        placeholder='Nome'
                        value={infos.name}
                        onChange={(e) => setInfos(prev => ({ ...prev, name: e.target.value }))}
                    />
                    <input
                        type="text"
                        placeholder='Link'
                        value={infos.link}
                        onChange={(e) => setInfos(prev => ({ ...prev, link: e.target.value }))}
                    />
                    <div
                        className={`downloads_add_button ${isButtonDisabled ? 'disabled' : ''}`}
                        onClick={handleDownload}
                    >
                        <p>Adicionar</p>
                    </div>
                </div>
            }
        </li>
    );
};
