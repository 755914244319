import { useQuery } from '@apollo/client'
import { GQL_MODELS } from '../../shared/Services/Graphql/querys'
import { CardsModels } from './cards_models'

export const LoopModels = () => {

    const { loading, error, data } = useQuery(
        GQL_MODELS,
    )

    if (loading) return <p>Loading...</p>
    if (error) {
        console.log(error)
    }

    return (
        <>
            {data?.models.map((model) => (
                <CardsModels model={model} key={model.id} />
            ))}
        </>
    )
}
