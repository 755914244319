import { Link } from "react-router-dom";
import { Users_icon } from "../../../assets/svg";

export const FloatAddUser = () => {
  return (
    <>
      <div className='add_button button_brand'>
        <Link
          to={{
            pathname: `/user/new`,
          }}
        >
          {Users_icon}
        </Link>
      </div>
    </>
  );
};
