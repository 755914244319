import React from "react";
import { MoreBlocks } from "../../components/more_blocks";

import './styles.scss';

const Empty = ({ typeBlock }: { typeBlock: string }) => {
    return (
        <div className="block_empty">
            <MoreBlocks position="inner" typeBlock={typeBlock} />
        </div>
    );
};

export default Empty;
