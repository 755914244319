import { useQuery } from '@apollo/client'
import { BrandModel } from '../../shared/interfaces/BrandModel'
import { GQL_BRANDS } from '../../shared/Services/Graphql/Graphql'

export const Selects_brands = (props: any) => {
  const { select } = props

  const { loading, error, data } = useQuery<{ brands: BrandModel[] }>(
    GQL_BRANDS,
  )

  if (error) return <p>Error...</p>
  if (loading) return <p>Loading...</p>

  function inputSelect(brand: string) {
    const idBrand = data?.brands.find((i: any) => i.name === brand)?.id
    idBrand !== undefined && select(idBrand)
  }

  return (
    <>
      <input
        className="select_brands"
        list="brands"
        placeholder="Selecione a Brand"
        onBlur={(e) => inputSelect(e.target.value)}
      />
      <datalist id="brands">
        {data?.brands.map((brand, i) => (
          <option value={brand.name} className="" key={i}></option>
        ))}
      </datalist>
    </>
  )
}
