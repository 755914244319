import React from "react";
import {
    Square_icon,
    Columns_icon,
    Column_icon,
    Heading_icon,
    Image_icon,
    Paragraph_icon,
    SVG_icon,
    Embedded_icon,
    Box_Children_icon,
} from "../../../../assets/svg";
import Types from "./types";

export const DefinedBlock = ({
    typeBlock = '',
    position,
    handleMoreBlockClick
}: {
    typeBlock?: string;
    position?: string;
    handleMoreBlockClick: (e: React.MouseEvent<HTMLDivElement>, type: string) => void
}) => {
    let filterBlock = Object.entries(Types); // Default to all types if no specific typeBlock is provided

    if (typeBlock && Types[typeBlock]) {
        const parentBlock = Types[typeBlock].parent || '';
        const parentBlockType = Types[parentBlock];

        const addTypeBlock = parentBlockType ? parentBlockType.allowTypes : Types[typeBlock].allowTypes;

        filterBlock = Object.entries(Types).filter(([type, obj]: [string, any]) =>
            !(addTypeBlock && (position === 'inner' || parentBlockType)) || (parentBlock && position === 'inner') ? !obj.parent : addTypeBlock.includes(type)
        );
    }

    return (
        <div
            className="add_types"
            style={{ gridTemplateColumns: `repeat(${filterBlock.length}, 1fr)` }}
        >
            {filterBlock.map(([type, blockData]: [string, any]) => (
                <div
                    key={type}
                    className="type_block"
                    onClick={(e) => handleMoreBlockClick(e, blockData)} // Passing type instead of blockData for simplicity and consistency
                >
                    {type === "box" && <Square_icon />}
                    {type === "box_children" && <Box_Children_icon />}
                    {type === "columns" && <Columns_icon />}
                    {type === "column" && <Column_icon />}
                    {type === "heading" && <Heading_icon />}
                    {type === "image" && <Image_icon />}
                    {type === "paragraph" && <Paragraph_icon />}
                    {type === "svg" && <SVG_icon />}
                    {type === "embedded" && <Embedded_icon />}
                </div>
            ))}
        </div>
    );
};
