import { useMutation } from '@apollo/client'
import React from 'react'
import { GQL_SECTION, MUTATION_CREATEMODULE } from '../../shared/Services/Graphql/Graphql'
import { Plus_icon } from '../../../assets/svg'

export const AddModule = ({ section }) => {
    const [createModule] = useMutation(MUTATION_CREATEMODULE, {
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: GQL_SECTION, variables: { id: section } },
        ]
    })

    function handleModule() {
        createModule({
            variables: {
                idSection: section,
                data: {
                    name: 'Novo módulo',
                    description: "<p>Essa é a descrição do novo módulo</p>",
                }
            }
        })
            .then((res) => {
                console.log(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <li className='modules_add' onClick={handleModule}>
            <Plus_icon />
            <p>Adicionar modulo</p>
        </li>
    )
}
