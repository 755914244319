import React, { useContext, useState } from "react";
import Types from "./types";
import './block.scss';
import { Block } from "./block";
import { ContentModule } from "../../module/content";
import { SelectedContext } from "../../../shared/context/SelectedModule";
import { MoreBlocks } from "./more_blocks";

interface TypeBlock {
    id: string;
    name: string;
    attributes: any;
    content: any;
    className: string;
    style: any;
    allowTypes: string[];
}

const Blocks = ({ block }: { block?: any }) => {
    const { selected } = useContext(SelectedContext)
    const contentArray = ContentModule()
    const blocks = block ? block : contentArray

    return blocks && blocks.length > 0 ? blocks.map((tool: TypeBlock, i: number) => {
        const { id, name, attributes, content, className, style } = tool;
        const ToolComponent = Types[name]?.const;

        return (
            <div
                key={i}
                className={`${selected === id ? 'selected ' : ''}edit_block`}
                data-id={id}
                
            >
                {ToolComponent && (
                    <Block
                        key={`${name}_${i}`}
                        name={name}
                        attributes={attributes}
                        content={content}
                        className={className}
                        style={style}
                    />
                )}
            </div>
        );
    }) : (
        <div className="edit_block">
            <MoreBlocks position="inner" typeBlock='first' />
        </div>
    );


};

export default Blocks;
