import { useMutation } from '@apollo/client'
import React from 'react'
import { GQL_BRAND, MUTATION_DELETELOGOBRAND } from '../../../shared/Services/Graphql/Graphql'
import { Delete_icon } from '../../../../assets/svg'

export const DeleteLogo = ({ file, idBrand }) => {
    const [deleteLogoBrand] = useMutation(MUTATION_DELETELOGOBRAND, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GQL_BRAND, variables: { id: idBrand } }],
    })

    function remove_logo() {
        deleteLogoBrand({
            variables: {
                id: file.id,
            },
        })
            .then((res) => {
                console.log(res)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div className='delete_logo' onClick={() => remove_logo()}>
            <Delete_icon width="36px" />
        </div>
    )
}
