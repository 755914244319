import { useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import { MUTATION_FILLUSER } from "../../shared/Services/Graphql/Graphql";

import { Brand_Universe_logo } from "../../../assets/svg";
import "./FillUser.scss";
import { useRef, useState } from "react";
// import UploadFile from "../UploadFile/UploadFile";

export default function FillUser() {
  const [fillNewUser] = useMutation(MUTATION_FILLUSER);

  const form_user = useRef<HTMLFormElement>(null);
  const name = useRef<HTMLInputElement>(null);
  const userName = useRef<HTMLInputElement>(null);
  const phone = useRef<HTMLInputElement>(null);
  const password = useRef<HTMLInputElement>(null);

  const [isPass, setPass] = useState("");
  const [isPhone, setPhone] = useState("");
  const [isConfPass, setConfPass] = useState("");

  function removeMackPhone(phone: any) {
    let n = phone.value.replace(/\D/g, "").substring(0, 11);
    phone.value = n;
  }

  function limitedPhone(phone: any) {
    let n = phone.value.replace(/\D/g, "").substring(0, 11);
    if (n.length === 10) {
      phone.value = n.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3");
      setPhone("");
    } else if (n.length === 11) {
      phone.value = n.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/, "($1) $2 $3-$4");
      setPhone("");
    } else {
      setPhone("error");
    }
  }

  let navigate = useNavigate();

  let params = useParams();

  function fillUser() {
    fillNewUser({
      variables: {
        data: {
          token: params.id,
          name: name.current?.value,
          userName: userName.current?.value,
          phone: phone.current?.value.replace(/\D/g, "").substring(0, 11),
          password: isPass,
          // profilePicture: profilePicture,
        },
      },
    })
      .then((res) => {
        if (res.data) {
          navigate(`/login`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <section className='createUser'>
      <div className='imagemsvg'>{Brand_Universe_logo}</div>
      <div className='container' style={{ height: `calc(100vh - 100px)` }}>
        <form ref={form_user}>
          <div className='container_form'>
            <div className='info_block'>
              <h3 className='titulo_block'>Dados Básicos</h3>
              <div className='content_block'>
                <div className='field'>
                  <div className='field_topo'>
                    <label htmlFor='name'>Nome Completo</label>
                  </div>
                  <input
                    type='text'
                    className='name'
                    id='name'
                    name='name'
                    ref={name}
                  />
                </div>

                <div className={`field ${isPhone != "" ? "error" : ""}`}>
                  <div className='field_topo'>
                    <label htmlFor='phone'>Telefone</label>
                    <p className='field_error'>{}</p>
                  </div>
                  <input
                    type='text'
                    className='phone'
                    id='phone'
                    name='phone'
                    placeholder='(00) 98765-4321'
                    ref={phone}
                    maxLength={11}
                    onBlur={(e) => limitedPhone(e.target)}
                    onFocus={(e) => removeMackPhone(e.target)}
                  />
                </div>
              </div>
            </div>
            <div className='info_block'>
              <h3 className='titulo_block'>Perfil</h3>
              <div className='content_block'>
                <div className='field'>
                  <div className='field_topo'>
                    <label htmlFor='userName'>Usuário</label>
                  </div>
                  <input
                    type='text'
                    className='userName'
                    id='userName'
                    name='userName'
                    ref={userName}
                  />
                </div>
                {/* <UploadFile></UploadFile> */}
              </div>
            </div>

            <div className='info_block'>
              <h3 className='titulo_block'>Senha</h3>
              <div className='content_block'>
                <div className='field_block'>
                  <div
                    className={`field ${
                      new RegExp(
                        /(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%&*_-])([0-9a-zA-Z!@#$%&*_-]{8})/
                      ).test(isPass)
                        ? ""
                        : "error"
                    }`}
                  >
                    <div className='field_topo'>
                      <label htmlFor='password'>Senha</label>
                      <p className='field_error'>{}</p>
                    </div>
                    <input
                      type='password'
                      className='password'
                      id='password'
                      name='password'
                      ref={password}
                      onChange={(e) => setPass(e.target?.value)}
                    />
                  </div>

                  <div
                    className={`field ${isConfPass != isPass ? "error" : ""}`}
                  >
                    <label htmlFor='confirmPassword'>Confirmar Senha</label>
                    <p className='field_error'>
                      {isConfPass != isPass &&
                        "O valor está diferente da senha"}
                    </p>
                    <input
                      type='password'
                      className='confirmPassword'
                      id='confirmPassword'
                      name='confirmPassword'
                      onChange={(e) => setConfPass(e.target?.value)}
                    />
                  </div>
                </div>
                <div className='info_content'>
                  <p>As senhas devem atender aos seguintes requisitos:</p>
                  <ul className='rules'>
                    <li
                      className={`rule ${
                        new RegExp(/[0-9a-zA-Z!@#$%&*_-]{8}/).test(isPass)
                          ? "correct"
                          : ""
                      }`}
                    >
                      Ter no mínimo 8 caracteres;
                    </li>
                    <li
                      className={`rule ${
                        new RegExp(/(?=.*[a-z])/).test(isPass) ? "correct" : ""
                      }`}
                    >
                      Ao menos uma letra Maiúscula;
                    </li>
                    <li
                      className={`rule ${
                        new RegExp(/(?=.*[A-Z])/).test(isPass) ? "correct" : ""
                      }`}
                    >
                      Uma letra minúscula;
                    </li>
                    <li
                      className={`rule ${
                        new RegExp(/(?=.*\d)/).test(isPass) ? "correct" : ""
                      }`}
                    >
                      Uma número;
                    </li>
                    <li
                      className={`rule ${
                        new RegExp(/(?=.*[!@#$%&*_-])/).test(isPass)
                          ? "correct"
                          : ""
                      }`}
                    >
                      Um número e um caracter especial.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <button type='button' onClick={fillUser}>
            SALVAR
          </button>
        </form>
      </div>
    </section>
  );
}
