import React from 'react';
import { useMutation } from '@apollo/client';
import { GQL_BRAND, MUTATION_UPDATEBRAND } from '../../../shared/Services/Graphql/Graphql';
import { DownloadCover } from './download_cover';

export const HighlightsSections = ({ idBrand, settings, sections, highlights }) => {
    const [updateBrand] = useMutation(MUTATION_UPDATEBRAND, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GQL_BRAND, variables: { id: idBrand } }],
    });

    const sectionsBrand = sections?.reduce((acc, section) => {
        acc.push(section);
        if (section.subsections) {
            acc.push(...section.subsections);
        }
        return acc;
    }, []) || [];

    const selectBrand = (selectedId) => {
        const newHighlights = highlights.map(h => h.id);
        if (!newHighlights.includes(selectedId) && selectedId && highlights.length < 5) {
            newHighlights.push(selectedId);
        }

        updateBrand({
            variables: {
                id: idBrand,
                data: {
                    highlights: newHighlights,
                },
            },
        });
    };

    const removeBrand = (selectedId) => {
        const newHighlights = highlights.filter(h => h.id !== selectedId).map(h => h.id);

        updateBrand({
            variables: {
                id: idBrand,
                data: {
                    highlights: newHighlights,
                },
            },
        });
    };


    const highlightsIds = highlights.map(highlight => highlight.id);
    const filteredSections = sectionsBrand.filter(section => !highlightsIds.includes(section.id));

    return (
        <div className='highlights_config'>
            <h3 className='title_section'>Seções de Destaque</h3>
            <ul className='highlights_list'>
                {highlights.map(s => (
                    <li className='highlights_item' key={s.id}>
                        {s.name}
                        <div className='delete' onClick={() => removeBrand(s.id)}>Deletar</div>
                    </li>
                ))}
            </ul>
            {highlights.length < 5 && <select className='select_brand' name='select_brand' id='select_brand' onChange={(e) => selectBrand(e.target.value)}>
                <option value=''>Selecione uma seção</option>
                {filteredSections.map(s => (
                    <option key={s.id} value={s.id}>{s.name}</option>
                ))}
            </select>}
            <div className='highlights_download'>
                <DownloadCover  idBrand={idBrand} settings={settings}/>
            </div>
        </div>
    );
};
