import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { EditDescriptionModule } from '../module/edit_description_module'
import { EditTitleSection } from '../sections/panel/edit_title_section'
import { EditTitleModule } from '../module/edit_title_module'
import { RemoveModule } from '../module/remove_module'
import { AddModule } from '../module/add_module'

import './list_module.scss'
import { Qmodule } from '../../shared/Services/Graphql/querys/q_module'

export const PanelInformationModel = ({ infos }) => {

    const { brand, section, module } = useParams()
    const { name, description } = Qmodule({ id: module as string })

    return (
        <>
            <div className="section_title">
                <EditTitleSection content={infos.name} id={infos.id} />
            </div>
            <div className="module_title">
                <EditTitleModule content={name} id={module} />
            </div>
            <EditDescriptionModule content={description} id={module} />
            <div className="section_modules">
                <ul className='modules_items'>
                    {infos.modules.map((e: any, i: number) => {
                        return (
                            <li key={i} className='module_item'>
                                <Link key={i} to={`/${brand}/${section}/${e.id}`}>
                                    {e.name}
                                </Link>
                                <div className='module_item_options'>
                                    <RemoveModule id={e.id} section={infos.id} />
                                </div>
                            </li>
                        )
                    })}
                    <AddModule section={infos.id} />
                </ul>
            </div>
        </>
    )
}
