import { Link } from "react-router-dom";
import { Brands_icon } from "../../../assets/svg";

export const FloatAddBrand = () => {
  return (
    <>
      <div className='add_button button_brand'>
        <Link
          to={{
            pathname: `/brand/new`,
          }}
        >
          {Brands_icon}
        </Link>
      </div>
    </>
  );
};
