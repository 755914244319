import { useState } from 'react'
import { imagemScale } from '../../shared/tools/image_scale'
import { useMutation } from '@apollo/client'
import {
  GQL_FILES,
  MUTATION_CREATEFILE,
} from '../../shared/Services/Graphql/Graphql'
import { Upload_icon } from '../../../assets/svg'

function UploadFile() {
  const [files] = useMutation(MUTATION_CREATEFILE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_FILES }],
  })

  const uploadImage = async (e: any) => {
    const formData = new FormData()
    formData.append('files', e)

    var xhr = new XMLHttpRequest()
    xhr.responseType = 'json'
    xhr.onload = () => {
      xhr.status === 200 &&
        (async () => {
          console.log(xhr.response)
          for (let file of xhr.response) {
            file['url'] = `https://files.universodamarca.com.br/${file.path}`
            await imagemScale(file.path).then((value: any) => {
              file['width'] = value.width
              file['height'] = value.height
            })

            await files({
              variables: {
                data: {
                  alt: file.originalname,
                  height: file.height,
                  name: file.originalname,
                  size: file.size,
                  type: file.mimetype,
                  url: file.url,
                  width: file.width,
                },
              },
            })
              .then((res) => {
                console.log(res)
              })
              .catch((err) => {
                console.log(err)
              })
          }
        })()
    }

    xhr.open('POST', 'https://files.universodamarca.com.br/files')
    xhr.send(formData)
  }

  return (
    <form className="upload_form">
      <div className='icon'><Upload_icon /></div>
      <label htmlFor="file">
        <span>Clique e selecione uma imagem.</span>
        {/* {label ? "" : "Arraste ou <span>selecione</span> uma imagem."} */}
      </label>
      <input
        type="file"
        className="file"
        id="file"
        name="file"
        onChange={(e) => uploadImage((e.target as any).files[0])}
      />
    </form>
  )
}

export default UploadFile
