import { useMutation } from '@apollo/client'
import React from 'react'
import { GQL_SECTION, MUTATION_DELETEMODULE } from '../../shared/Services/Graphql/Graphql'
import { Delete_icon } from '../../../assets/svg'

export const RemoveModule = ({ id, section }) => {
    const [removeModulo] = useMutation(MUTATION_DELETEMODULE, {
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: GQL_SECTION, variables: { id: section } },
        ]
    })

    const handleRemoveModule = () => {
        removeModulo({
            variables: {
                id: id,
            }
        })
            .then((res) => {
                console.log(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div onClick={handleRemoveModule}>
            <Delete_icon />
        </div>
    )
}
