import { useQuery } from '@apollo/client'
import { UserModel } from '../../shared/interfaces/UserModel'
import { GQL_USERS } from '../../shared/Services/Graphql/Graphql'
import { Cards_User } from './cards_user'

export const UsersLoop = (props: any) => {
  const { idUrl } = props

  const { loading, error, data } = useQuery<{ users: UserModel[] }>(GQL_USERS, {
    fetchPolicy: 'network-only',
  })

  if (loading) return <p>Loading...</p>

  if (error) return <p>Loading...</p>

  return (
    <>
      {data?.users.map((user) => (
        <Cards_User user={user} key={user.id} idUrl={idUrl} />
      ))}
    </>
  )
}
