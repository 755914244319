import React, { useState, useEffect } from 'react';

interface Props {
    id: string;
    value: string;
    type: string;
    childType?: string;
    object?: { [key: string]: any }; // Ajuste para permitir um objeto com chaves de string e valores de qualquer tipo
    placeholder: string;
    onBlur: (value: string | { [key: string]: any }, typeInput: string) => void; // Ajuste para permitir que o primeiro argumento seja string ou objeto
}

const InputText = (props: Props) => {
    const { id, value, type, childType, object, placeholder, onBlur } = props;
    const [inputValue, setInputValue] = useState(value);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        if (object && childType) {
            const updatedObject = { ...object, [childType]: e.target.value };
            onBlur(updatedObject, type);
        } else {
            onBlur(e.target.value, type);
        }
    };

    return (
        <input
            type="text"
            name={id}
            id={id}
            value={inputValue}
            placeholder={placeholder}
            onChange={(e) => setInputValue(e.target.value)}
            onBlur={handleBlur}
        />
    );
};

export default InputText;
