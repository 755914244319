import React, { useEffect, useRef } from "react";
import Empty from "../empty";

import "./styles.scss";

const Column = ({ id, name, attributes, className, children, style }) => {
    const { tag } = attributes;

    const column = useRef<HTMLDivElement>(null);

    if (column.current) {
        const columnBlock = column.current.closest('.edit_block') as HTMLElement;
        columnBlock &&
            Object.keys(style).forEach(key => {
                columnBlock.style[key] = style[key];
            });
    }


    const contentToRender = children?.props?.block.length ? children : <Empty typeBlock={name} />;

    return React.createElement(
        "div",
        {
            className: className,
            "data-id": id,
            ref: column,
        },
        contentToRender
    );
};

export default Column;
