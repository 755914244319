import React, { useState } from "react";
import Empty from "../empty";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GQL_SECTION, GQL_SECTIONBRAND } from "../../../../shared/Services/Graphql/Graphql";

import "./styles.scss";
import { ImageBox } from "./image_box";
import { ModalFilesBox } from "./modal_files_box";
import { Image_Add_icon, Image_icon } from "../../../../../assets/svg";
import { ContentModule } from "../../../module/content";

const BoxChildren = ({ id, name, attributes, className, children, style }) => {
    const { brand, section } = useParams();
    const { download, columns, rows, textStyle } = attributes;

    const dataModule = ContentModule()

    const [activeModal, setActiveModal] = useState(null);

    const { error, loading, data } = useQuery(GQL_SECTIONBRAND, {
        variables: { brand: brand, section: section },
    });

    const subsections = data?.sectionBrand.subsections;

    const toggleModal = (itemId) => {
        if (activeModal === itemId) {
            setActiveModal(null);
        } else {
            setActiveModal(itemId); // Caso contrário, abra o modal para o item clicado
        }
    };



    return (
        <div className={className}
            style={{
                ...style,
                gridTemplateColumns: `repeat(${columns}, 1fr)`,
                gridAutoRows: `minmax(calc(100% / ${rows}), auto)`
            }}
        >
            {subsections?.map((item, index) => {
                return (
                    <React.Fragment key={index}>
                        <a>
                            <div className="change_figure" onClick={() => toggleModal(item.id)}>
                                <Image_Add_icon width="24px" />
                            </div>
                            {item.cover?.id ? <ImageBox id={item.cover.id} /> : (
                                <div className="select" onClick={() => toggleModal(item.id)}>
                                    <Image_icon width="48px" />
                                    <p>Selecionar arquivo</p>
                                </div>
                            )}
                            <p className="name" style={textStyle}>{item.name}</p>
                        </a>
                        {activeModal === item.id && <ModalFilesBox setActive={() => setActiveModal(null)} id={item.id} title={item.name} />}
                    </React.Fragment>
                )
            })}
            <a>
                <img src={download.url} alt="Adicionar" />
                <p className="name" style={textStyle}>Downloads</p>
            </a>
        </div>
    );
};

export default BoxChildren;
