import { Link } from 'react-router-dom'
import './Dashboard.scss'
import { BrandsLoop } from '../../widgets/brands/brands_loop'
import { UsersLoop } from '../../widgets/users/users_loop'
import { LoopModels } from '../../widgets/models/loop_models'

export default function Dashboard() {
  return (
    <>
      <section className="dashboard">
        <div className="container">
          <section className="dash_models dash_section">
            <div className="titulo_section_dash">
              <h3>Modelos recentes</h3>
            </div>
            <div className="loop_section_dash modelss">
              <LoopModels />
            </div>
            <div className="button_section_dash">
              <Link
                to={{
                  pathname: `/modelss/`,
                }}
              >
                <button>Ver tudo</button>
              </Link>
            </div>
          </section>
          <section className="dash_brand dash_section">
            <div className="titulo_section_dash">
              <h3>Brands recentes</h3>
            </div>
            <div className="loop_section_dash brands">
              <BrandsLoop sort="desc" take={8} visualization={'card'} />
            </div>
            <div className="button_section_dash">
              <Link
                to={{
                  pathname: `/brands/`,
                }}
              >
                <button>Ver tudo</button>
              </Link>
            </div>
          </section>
          <section className="dash_brand dash_section">
            <div className="titulo_section_dash">
              <h3>Pessoas recentes</h3>
            </div>
            <div className="loop_section_dash brands">
              <UsersLoop />
            </div>
            <div className="button_section_dash">
              <Link
                to={{
                  pathname: `/users/`,
                }}
              >
                <button>Ver tudo</button>
              </Link>
            </div>
          </section>
        </div>
      </section>
    </>
  )
}
