import { useMutation } from "@apollo/client";
import jwt_decode from "jwt-decode";
import { createContext, useEffect, useState } from "react";
import { MUTATION_LOGINUSER } from "../Services/Graphql/Graphql";
import { IAuthProvider, IContext, IUser } from "./AuthTypes";

export const AuthContext = createContext<IContext>({} as IContext);

export const AuthProvider = ({ children }: IAuthProvider) => {
  const [LoginUser] = useMutation(MUTATION_LOGINUSER);
  const [user, setUser] = useState<IUser | null>();

  useEffect(() => {
    //@ts-ignore
    const userLogged = JSON.parse(localStorage.getItem("bu_login"));
    const currentTime = new Date();
    const expiredToken = new Date(0);

    expiredToken.setUTCSeconds(userLogged?.expired);

    if (userLogged) {
      if (currentTime > expiredToken) {
        logout();
      }
      setUser(userLogged);
    }
  }, []);

  async function authenticate(
    username: string,
    password: string,
    isAuth: string,
    setAuth: any,
    navigate: any
  ) {
    LoginUser({
      variables: {
        data: {
          userName: username,
          email: username,
          password: password,
        },
      },
    })
      .then((res) => {
        if (res.data) {
          const decoded: any = jwt_decode(res.data.loginUser.token);
          setUser({ userId: decoded.id });

          localStorage.setItem(
            "bu_login",
            JSON.stringify({
              userId: decoded.id,
              expired: decoded.exp,
            })
          );
          localStorage.setItem("token", res.data.loginUser.token);
        }
        navigate("/dashboard");
      })
      .catch((err) => {
        setAuth(err.message);
      });
  }

  const logout = () => {
    setUser(null);
    localStorage.clear();
  };

  return (
    <>
      <AuthContext.Provider value={{ ...user, authenticate, logout }}>
        {children}
      </AuthContext.Provider>
    </>
  );
};
