import React from "react";
import EditableContent from "../../editableContent"; // Ajuste o caminho conforme necessário

const Heading = ({ attributes, content, className, style }) => {
  const { tag } = attributes;
  
  return React.createElement(
    tag,
    {
      className: className,
      style: style,
    },
    <EditableContent
      content={content}
      className={className}
    />
  );
};

export default Heading;
