import React from "react";
import Empty from "../empty";

const Box = ({ id, name, attributes, className, children, style }) => {
    const { tag } = attributes;

    const contentToRender = children?.props?.block.length ? children : <Empty typeBlock={name} />;

    return React.createElement(
        tag || "div",
        {
            className: className,
            style: style,
            "data-id": id,
        },
        contentToRender
    );
};

export default Box;
