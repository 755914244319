const types = [
  "box",
  "box_children",
  "columns",
  "column",
  "heading",
  "paragraph",
  "image",
  "svg",
  "embedded",
];

const Types = {};

types.forEach((type) => {
  Types[type] = require(`../types/${type}/block.json`);
  Types[type].const = require(`../types/${type}`).default;
});

export default Types;
