import React, { useContext} from 'react';
import { useParams } from 'react-router-dom';
import { SelectedContext } from "../../../shared/context/SelectedModule";
import { ContentModule } from "../../module/content";
import './panel.scss';
import { useMutation } from '@apollo/client';
import { GQL_MODULE, MUTATION_UPDATEMODULE } from '../../../shared/Services/Graphql/Graphql';
import { Copy_icon, Delete_icon, Paste_icon } from '../../../../assets/svg';

const Block = ({ block, level, selectBlock, onDelete }) => {
  const handleDelete = () => {
    onDelete(block.id);
  };
  return (
    <div className="edit_block" data-id={block.id} style={{ paddingLeft: '8px' }}>
      {block.name && (
        <div className="edit_block_item">
          <div className={`${selectBlock === block.id ? 'selected ' : ''}edit_block_name`} >
            <p>
              {`${block.name}`}
            </p>
            <div className="edit_block_info" >
              <p>{block.id}</p>
              <p>{`Nível: ${level}`}</p>
            </div>
          </div>
          <div className='edit_block_buttons' onClick={handleDelete}>
            <Delete_icon width="36px" />
          </div>
        </div>
      )}
      {Array.isArray(block.content) && (
        block.content.map((childBlock, index) => (
          <Block
            key={index}
            block={childBlock}
            level={level + 1}
            selectBlock={selectBlock}
            onDelete={onDelete}
          />
        ))
      )}
    </div>
  );
};

const BlocksRenderer = ({ blocks, level, selectBlock, onDelete }) => {
  return blocks?.map((block, index) => (
    <Block
      key={index}
      block={block}
      level={level}
      selectBlock={selectBlock}
      onDelete={onDelete}
    />
  ));
};

export const PanelSection = () => {
  const { selected } = useContext(SelectedContext);
  const contentArray = ContentModule();
  const { module } = useParams();


  const [UpdateModule] = useMutation(MUTATION_UPDATEMODULE, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_MODULE, variables: { id: module } }],
  });

  const handleDeleteBlock = ({ obj, id }) => {
    if (Array.isArray(obj)) {
      return obj.reduce((acc, item) => {
        if (Array.isArray(item.content)) {
          const newItem = { ...item, content: handleDeleteBlock({ obj: item.content, id }) };
          if (item.id !== id) {
            acc.push(newItem);
          }
        } else {
          if (item.id !== id) {
            acc.push(item);
          }
        }
        return acc;
      }, []);
    }
    return obj;
  };

  const updateSection = (id?: string, content?: string) => {
    const updatedContentArray = !content ? handleDeleteBlock({ obj: contentArray, id: id }) : content

    UpdateModule({
      variables: {
        id: module,
        data: {
          content: JSON.stringify(updatedContentArray),
        },
      },
    })
      .then(res => console.log(res))
      .catch(err => console.log(err));
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(JSON.stringify(contentArray))
      .then(() => {
        alert("Conteúdo copiado para a área de transferência!");
      })
      .catch((error) => {
        console.error("Erro ao copiar conteúdo: ", error);
      });
  };

  const handlePasteFromClipboard = () => {
    navigator.clipboard.readText()
      .then((text) => {
        try {
          const parsedContent = JSON.parse(text);
          updateSection(undefined, parsedContent); // Passa undefined para o id e o conteúdo colado
          alert("Conteúdo colado da área de transferência!");
        } catch (error) {
          console.error("Erro ao colar conteúdo: ", error);
          alert("Erro ao colar conteúdo: formato inválido!");
        }
      })
      .catch((error) => {
        console.error("Erro ao ler conteúdo da área de transferência: ", error);
        alert("Erro ao ler conteúdo da área de transferência!");
      });
  };


  return (
    <>
      <div className='copy_paste'>
        <div className='copy' onClick={handleCopyToClipboard}>
          <Copy_icon />
        </div>
        <div className='paste' onClick={handlePasteFromClipboard}>
          <Paste_icon />
        </div>
      </div>
      <BlocksRenderer
        blocks={contentArray}
        level={1}
        selectBlock={selected}
        onDelete={updateSection}
      />
    </>
  );
};
