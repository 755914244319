import { useQuery } from '@apollo/client'
import { GQL_FILES } from '../../shared/Services/Graphql/Graphql'
import { FilesModel } from '../../shared/interfaces/FilesModel'

import './file.scss'

export default function Loop_file(props: any) {
  const { selectFile, select } = props

  const { loading, error, data } = useQuery<{ files: FilesModel[] }>(
    GQL_FILES,
    {
      fetchPolicy: 'network-only',
    },
  )

  if (loading) return <p>Loading...</p>
  if (error) return <p>Alguma coisa está errada</p>

  const files = data && [...data?.files].reverse()
  

  return (
    <div className="galery_image">
      {files?.map((f, i) => (
        <div
          className={`image ${select === f.id ? 'select_info' : ''}`}
          key={i}
        >
          <figure onClick={() => selectFile({ id: f.id, url: f.url, name: f.name, width: f.width, height: f.height })}>
            <img src={f.url} alt='' />
          </figure>
        </div>
      ))}
    </div>
  )
}
