import { GQL_MODULE } from "../Graphql";
import { useQuery } from "@apollo/client";

export const Qmodule = ({ id }: { id: string }) => {
  const { data, error } = useQuery(GQL_MODULE, {
    variables: { id: id },
  });

  if (error) console.log(error);
  const module = data?.module;

  return { ...module };
};
