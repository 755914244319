import { Component, createRef } from 'react'
import { Link } from 'react-router-dom'
import DeleteSection from './delete_section'
import { EditSection } from './edit_section'

class MenuItem extends Component<any, any> {
  ptitle: any
  childRef: any
  constructor(props: any) {
    super(props)
    this.state = {
      edit: false,
    }
    this.ptitle = createRef()
    this.childRef = createRef()
  }

  editMode = (editMode: any) => {
    editMode ? this.setState({ edit: true }) : this.setState({ edit: false })
  }

  render() {
    return (
      <>
        <div
          className={`${this.props.fatherItem
              ? 'menu_brand_content'
              : 'submenu_brand_content'
            } ${this.state.edit ? 'edit_section' : ''} content_item`}
        >
          {this.props.fatherItem ? (
            <Link
              to={
                this.state.edit
                  ? ''
                  : {
                    pathname: `/${this.props.slug}/${this.props.sectionData.id}/${this.props.sectionData?.modules[0]?.id}`,
                  }
              }
            >
              <p
                className="menu_brand_name"
                contentEditable={this.state.edit}
                ref={this.ptitle}
              >
                {this.props.sectionData.name}
              </p>
            </Link>
          ) : (
            <Link
              to={
                this.state.edit
                  ? ''
                  : {
                    pathname: `/${this.props.slug}/${this.props.sectionData.id}/${this.props.sectionData?.modules[0]?.id}`,
                  }
              }
            >
              <p
                className="submenu_brand_name"
                contentEditable={this.state.edit}
                suppressContentEditableWarning={this.state.edit}
                ref={this.ptitle}
              >
                {this.props.sectionData.name}
              </p>
            </Link>
          )}
          <div
            className={`${this.state.edit ? 'edit_section' : ''
              } brand_item_options`}
          >
            <DeleteSection
              id={this.props.sectionData.id}
              sectionParent={this.props.sectionParent}
              brand={this.props.brand}
            ></DeleteSection>
            <EditSection
              id={this.props.sectionData.id}
              sectionParent={this.props.sectionParent}
              ptitle={this.ptitle}
              edit={this.state.edit}
              editMode={this.editMode}
            ></EditSection>
          </div>
        </div>
      </>
    )
  }
}

export default MenuItem
