import { useMutation } from '@apollo/client';
import React, { useState, useEffect } from 'react';
import { GQL_BRAND, MUTATION_UPDATELOGOBRAND } from '../../../shared/Services/Graphql/Graphql';

export const UpdateLogoName = ({ logo, idBrand }) => {
    const [name, setName] = useState(logo.type);
    const [isEditable, setIsEditable] = useState(true);

    const [updateLogoName] = useMutation(MUTATION_UPDATELOGOBRAND, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GQL_BRAND, variables: { id: idBrand } }],
    });

    useEffect(() => {
        if (logo.type === 'PRIMARY' || logo.type === 'SECONDARY') {
            setIsEditable(false);
        }
    }, [logo.type]);

    function update_logo_name(updatedName: string) {
        const trimmedName = updatedName.trim();
        if (trimmedName && isEditable) {
            updateLogoName({
                variables: {
                    id: logo.id,
                    data: {
                        type: trimmedName,
                    },
                },
            })
                .then((res) => {
                    console.log(res);
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }

    return (
        <input
            type="text"
            className='logo_name'
            value={name}
            onChange={(e) => setName(e.target.value.replace(/\s/g, '').toLocaleUpperCase())}
            onBlur={() => update_logo_name(name)}
            disabled={!isEditable}
        />
    );
};
