import React, { useState } from "react";
import { Image_icon } from "../../../../assets/svg";
import { ModalFilesBrand } from "../modal_files_brand";
import { useMutation } from "@apollo/client";
import { GQL_BRAND, MUTATION_CREATELOGOBRAND } from "../../../shared/Services/Graphql/Graphql";
import { DeleteLogo } from "./delete";
import { UpdateLogoImage } from "./updateImage";
import { UpdateLogoName } from "./updateName";

// Defina uma interface para o tipo de imagem definida
interface DefinedImageType {
    id: string;
    url: string;
    name: string;
    width?: number;
    height?: number;
}

export const Logos = ({ idBrand, logos }) => {
    const [add, setAdd] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [otherType, setOtherType] = useState('');
    const [activeModal, setActiveModal] = useState(false);
    const [definedImage, setDefinedImage] = useState<DefinedImageType | null>(null);

    const [createLogoBrand] = useMutation(MUTATION_CREATELOGOBRAND, {
        awaitRefetchQueries: true,
        refetchQueries: [{ query: GQL_BRAND, variables: { id: idBrand } }],
    });

    const selectBrand = () => {
        if (!definedImage || !selectedType || (selectedType === 'OTHERS' && !otherType)) {
            alert('Por favor, selecione um tipo e uma imagem para o logo.');
            return;
        }

        createLogoBrand({
            variables: {
                id: idBrand,
                data: {
                    type: selectedType === "OTHERS" ? otherType : selectedType,
                    file: definedImage.id,
                }
            },
        }).then(() => {
            setAdd(false);
            setActiveModal(false);
            setDefinedImage(null);
        }).catch((error) => {
            console.error("Failed to update brand logos", error);
        });
    };

    const allTypes = ['PRIMARY', 'SECONDARY', 'OTHERS'];
    const availableTypes = allTypes.filter(type => !logos.some(logo => logo.type === type));

    return (
        <div className='logos_config'>
            <h3 className='title_section'>Logos</h3>
            <div className='logos_list'>
                {logos.map(logo => (
                    <div className='logo_item' key={logo.id}>
                        <div className="logo_options">
                            <UpdateLogoName logo={logo} idBrand={idBrand} />
                            <DeleteLogo file={logo} idBrand={idBrand} />
                        </div>
                        <UpdateLogoImage logo={logo} idBrand={idBrand} />
                    </div>
                ))}

                <div className='add_logo'>
                    {!add ? (
                        <div className="click_action_logo" onClick={() => setAdd(true)}>
                            <div className='add_logo_icon'>+</div>
                            <p>Adicionar logo</p>
                        </div>
                    ) : (
                        <div className="form_action_logo" >
                            <div className="form_action_image" onClick={() => setActiveModal(true)}>
                                {definedImage ? (
                                    <img src={definedImage.url} alt={definedImage.name} />
                                ) : (
                                    <div className="select">
                                        <Image_icon width="48px" />
                                        <p>Selecionar arquivo</p>
                                    </div>
                                )}
                            </div>
                            {activeModal && (
                                <ModalFilesBrand
                                    setActiveModal={setActiveModal}
                                    setDefinedImage={setDefinedImage}
                                    title={`Logo - ${selectedType}`} />
                            )}
                            <div className="form_action_type">
                                <select name="type" id="type" value={selectedType} onChange={(e) => setSelectedType(e.target.value)}>
                                    <option value="">Selecione um tipo</option>
                                    {availableTypes.map(type => (
                                        <option key={type} value={type}>{type}</option>
                                    ))}
                                </select>
                                {selectedType === 'OTHERS' && (
                                    <input
                                        type="text"
                                        name="customType"
                                        id="customType"
                                        placeholder="Adicione um ID personalizado"
                                        value={otherType}
                                        onChange={(e) => setOtherType(e.target.value.toUpperCase())}
                                    />
                                )}
                            </div>
                            <div className="form_action_buttons">
                                <div className="add" onClick={selectBrand}>
                                    Adicionar
                                </div>
                                <div className="Cancelar" onClick={() => setAdd(false)}>
                                    Cancelar
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
