import { useMutation } from "@apollo/client"
import { GQL_BRAND, MUTATION_UPDATELOGOBRAND } from "../../../shared/Services/Graphql/Graphql"
import { ModalFilesBrand } from "../modal_files_brand"
import { useState } from "react";

// Defina uma interface para o tipo de imagem definida
interface DefinedImageType {
  id: string;
  url: string;
  name: string;
  width?: number;
  height?: number;
}

export const UpdateLogoImage = ({ logo, idBrand }) => {
  const [activeModal, setActiveModal] = useState(false)
  const [definedImage, setDefinedImage] = useState<DefinedImageType | null>(null)

  const [updateLogo] = useMutation(MUTATION_UPDATELOGOBRAND, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_BRAND, variables: { id: idBrand } }],
  })

  function update_logo(idFile: string) {
    updateLogo({
      variables: {
        id: logo.id,
        data: {
          file: idFile,
        },
      },
    })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="logo_edit_image">
      <figure className="logo_image" onClick={() => setActiveModal(true)}>
        <img src={logo.file?.url} alt={logo.file?.name} />
      </figure>
      {activeModal && (
        <ModalFilesBrand
          setActiveModal={setActiveModal}
          setDefinedImage={setDefinedImage}
          update={update_logo}
          title={`Logo - ${logo.type}`}
        />
      )}
    </div>
  )
}
