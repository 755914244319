import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import "./NewUser.scss";
import { MUTATION_CREATENEWUSER } from "../../../shared/Services/Graphql/Graphql";
import { useRef, useState } from "react";
import { NivelUser } from "../../../widgets/users/nivel/nivel";

export default function NewUser() {
  const inputEmail = useRef<HTMLInputElement>(null);
  const [createUser] = useMutation(MUTATION_CREATENEWUSER);
  const navigate = useNavigate();

  function createNewUser() {
    createUser({
      variables: {
        data: {
          email: inputEmail.current?.value,
        },
      },
    })
      .then((res) => {
        if (res.data) {
          console.log(res.data);
          navigate(`/users`);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <section className='new_user'>
      <div className='container'>
        <div className='titulo_section'>
          <h2>Convidar novo usuário</h2>
        </div>
        <form className='forms'>
          <div className='container_form'>
            <div className='field'>
              <div className='email_input'>
                <label htmlFor='email'>Email</label>
                <input
                  type='text'
                  className='email'
                  id='email'
                  name='email'
                  ref={inputEmail}
                />
              </div>
            </div>
            <div className='field'>
              <p className='bold'>Nível de acesso</p>
              <div className='select_type'>
                {NivelUser.map(
                  (n: any, i: number) =>
                    n.slug != "inativo" && (
                      <div className='type_user' key={i}>
                        <input
                          name='type_user'
                          type='radio'
                          id={n.slug}
                          value={n.slug}
                        />
                        <label className={n.slug} htmlFor={n.slug}>
                          <span>{n.name}</span>
                          {n.icon}
                        </label>
                      </div>
                    )
                )}
              </div>
            </div>
          </div>
        </form>
        <button type='button' onClick={createNewUser}>
          Enviar convite
        </button>
      </div>
    </section>
  );
}
