import { useMutation, useQuery } from '@apollo/client'
import {
  GQL_USER,
  GQL_USERS,
  MUTATION_DELETEUSER,
  MUTATION_REQUESTNEWCREATEUSERTOKEN,
} from '../../shared/Services/Graphql/Graphql'
import { UserModel } from '../../shared/interfaces/UserModel'

import './user.scss'
import { Avatar_Icon, Delete_icon, Send_Mail_icon } from '../../../assets/svg'
import { Add_brand_user } from './add_brand_user'
import { List_brand_user } from './list_brand_user'

export const Config_user = (props: any) => {
  const { idUrl } = props

  const { loading, error, data } = useQuery<{ user: UserModel }>(GQL_USER, {
    variables: { id: idUrl },
    fetchPolicy: 'network-only',
  })

  const user = data ? data.user : null

  const [newRequestNewCreateUserToken] = useMutation(
    MUTATION_REQUESTNEWCREATEUSERTOKEN,
  )

  const [deleteUser] = useMutation(MUTATION_DELETEUSER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_USERS }],
  })

  const userDelete = () => {
    deleteUser({
      variables: {
        id: idUrl,
      },
    })
      .then((res) => {
        console.log(res.data)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  const resendEMail = () => {
    newRequestNewCreateUserToken({
      variables: {
        email: user?.email,
      },
    })
      .then((res) => {
        if (res.data) {
          alert('E-mail enviado com sucesso!')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }

  if (loading) return <p>Loading...</p>
  if (error) return <></>

  return (
    <div className="conf_user">
      <div className="head_conf_user">
        <div className="avatar_user">{Avatar_Icon}</div>
      </div>
      <div className="infos_user">
        <div className="info_user">
          <p className="field_title">Usuário</p>
          <p className="field_content">{user?.userName}</p>
        </div>
        <div className="info_user">
          <p className="field_title">Nome</p>
          <p className="field_content">{user?.name}</p>
        </div>
        <div className="info_user">
          <p className="field_title">E-mail</p>
          <p className="field_content">{user?.email}</p>
        </div>
        <div className="info_user">
          <p className="field_title">Telefone</p>
          <p className="field_content">{user?.phone}</p>
        </div>
      </div>
      <div className="brands_user">
        <div className="head_brands_user">
          <h4 className="title">brands</h4>
        </div>
        <div className="content_brands_user">
          <Add_brand_user id={user?.id} />
          <List_brand_user id={user?.id} brands={user?.brands} />
        </div>
      </div>
      <div className="options_user">
        <div className="option_user">
          <div className="delete_user" onClick={() => userDelete()}>
            <Delete_icon />
          </div>
          <div className="delete_user" onClick={() => resendEMail()}>
            {Send_Mail_icon}
          </div>
        </div>
      </div>
    </div>
  )
}
