import {
    Close_icon,
} from "../../../assets/svg";
import "./modal.scss";


export const Modal = ({ open, setOpen, children }: any) => {
    if (!open) return null;
    return (
        <>
            <div className="modal modal_select_image">
                <div className="modal_content">
                    <div className="modal_header">
                        <div className="icon" onClick={() => setOpen(false)}>
                            {Close_icon}
                        </div>
                    </div>
                    {children}
                </div>
                <div className="modal_back" onClick={() => setOpen(false)}></div>
            </div>
        </>
    );
};
