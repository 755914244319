import { useMutation } from '@apollo/client'
import { Selects_brands } from './selects_brands'
import {
  GQL_USER,
  MUTATION_PUSHBRANDTOUSER,
} from '../../shared/Services/Graphql/Graphql'

export const Add_brand_user = (props: any) => {
  const { id } = props
  const [selectBrand] = useMutation(MUTATION_PUSHBRANDTOUSER, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: GQL_USER, variables: { id: id } }],
  })

  function select(idBrand: any) {
    selectBrand({
      variables: {
        id: id,
        data: {
          brands: [idBrand],
        },
      },
    })
      .then((res) => {
        console.log(res)
      })
      .catch((err) => {
        console.log(err)
      })
    {
    }
  }

  return (
    <div className="add_brands_user">
      <Selects_brands select={select} />
    </div>
  )
}
