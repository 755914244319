import React from 'react'
import { Link, useParams } from 'react-router-dom'
import { Qmodule } from '../../../shared/Services/Graphql/querys/q_module'
import { EditDescriptionModule } from '../../module/edit_description_module'
import { EditTitleSection } from './edit_title_section'
import { EditTitleModule } from '../../module/edit_title_module'
import { RemoveModule } from '../../module/remove_module'
import { AddModule } from '../../module/add_module'
import { AddDownload } from './add_download'
import { Download_icon, Edit_icon } from '../../../../assets/svg'

import './list_module.scss'
import './list_downloads.scss'
import { EditDownload } from './edit_download'
import { RemoveDownload } from './remove_download'

export const PanelInformation = ({ infos }) => {

    const [downloadSelect, setDownloadSelect] = React.useState('')

    const { brand, section, module } = useParams()
    const { name, description } = Qmodule({ id: module as string })


    console.log(infos)

    return (
        <>
            <div className="section_title">
                <EditTitleSection content={infos.name} id={infos.id} />
            </div>
            <div className="module_title">
                <EditTitleModule content={name} id={module} />
            </div>
            <EditDescriptionModule content={description} id={module} />
            <div className="section_modules">
                <ul className='modules_items'>
                    {infos.modules.map((e: any, i: number) => {
                        return (
                            <li key={i} className='module_item'>
                                <Link key={i} to={`/${brand}/${section}/${e.id}`}>
                                    {e.name}
                                </Link>
                                <div className='module_item_options'>
                                    <RemoveModule id={e.id} section={infos.id} />
                                </div>
                            </li>
                        )
                    })}
                    <AddModule section={infos.id} />
                </ul>
            </div>
            <div className="section_downloads">
                <ul className='downloads_items'>
                    {infos.downloads ? infos.downloads?.map((download: any, index: number) => {
                        return (
                            <li className="downloads_item" key={index} >
                                <div className='downloads_item_infos'>
                                    <div className='downloads_item_name'>
                                        <Download_icon width="24px" />
                                        <Link to={download.file ? download.file : download.link} target='_blank' rel="noreferrer">{download.name}</Link>
                                    </div>
                                    <div className='downloads_item_options'>
                                        <div onClick={() => setDownloadSelect(downloadSelect ? '' : download.id)}>
                                            {Edit_icon}
                                        </div>
                                        {downloadSelect === download.id && <RemoveDownload id={download.id} section={infos.id} />}
                                    </div>
                                </div>
                                {downloadSelect === download.id && <EditDownload id={download.id} download={download} section={infos.id} />}
                            </li>
                        )
                    })
                        : ''}
                    <AddDownload section={infos.id} />
                </ul>
            </div>
        </>
    )
}
