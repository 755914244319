import React from "react";
import Empty from "../empty";

const Columns = ({ attributes, content, name, className, children, allowTypes, style }) => {
    const contentToRender = children?.props?.block.length ? children : <Empty typeBlock={name} />;

    return React.createElement(
        "div",
        {
            className: className,
            style: style,
            allowTypes
        },
        contentToRender
    );
};


export default Columns;