import { useMutation } from "@apollo/client";
import { Delete_icon } from "../../../../assets/svg";
import {
  GQL_BRAND,
  GQL_SECTION,
  MUTATION_DELETESECTION,
} from "../../../shared/Services/Graphql/Graphql";

const DeleteSection = (props: any) => {
  const { id, sectionParent, brand } = props;
  const [deleteSection] = useMutation(MUTATION_DELETESECTION, {
    awaitRefetchQueries: true,
    refetchQueries: [
      brand && { query: GQL_BRAND, variables: { id: brand } },
      sectionParent && { query: GQL_SECTION, variables: { id: sectionParent } },
    ],
  });

  function removeSection() {

    deleteSection({
      variables: {
        id: id,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }
  return (
    <div className='delete' onClick={removeSection}>
      <Delete_icon />
    </div>
  );
};

export default DeleteSection;
