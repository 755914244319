import { useQuery } from "@apollo/client";
import { createContext, useEffect, useState } from "react";
import { GQL_MODEL } from "./querys";

export const ModelContext = createContext({ model: [] });

export const ModelProvider = ({ idModel, children }: any) => {
  const { error, data } = useQuery(GQL_MODEL,
    {
      variables: { id: idModel },
      fetchPolicy: "cache-and-network"
    }
  );
  const [model, setModel] = useState([]);

  useEffect(() => {
    if (!error) {
      setModel(data?.model || []);
    }
  }, [error, data]);

  if (error) {
    console.log(error);
  }

  return (
    <ModelContext.Provider value={{ model: model }}>
      {children}
    </ModelContext.Provider>
  );
};
