import { useMutation } from '@apollo/client'
import React from 'react'
import { GQL_SECTION, MUTATION_DELETEDOWNLOAD } from '../../../shared/Services/Graphql/Graphql'
import { Delete_icon } from '../../../../assets/svg'

export const RemoveDownload = ({ id, section }) => {
    const [removeDownload] = useMutation(MUTATION_DELETEDOWNLOAD, {
        awaitRefetchQueries: true,
        refetchQueries: [
            { query: GQL_SECTION, variables: { id: section } },
        ]
    })
    const handleRemoveDownload = () => {
        removeDownload({
            variables: {
                id: id,
            }
        })
            .then((res) => {
                console.log(res.data)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    return (
        <div onClick={handleRemoveDownload}>
            <Delete_icon />
        </div>
    )
}
