import axios from "axios";
import "./UploadFile.scss";

import { Upload_icon } from "../../../assets/svg";
import { useMutation } from "@apollo/client";

const UploadFile = (file: any) => {
  const { id, mutation, variable, refetch, tag, label } = file;

  const [variablesFile] = useMutation(mutation, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: refetch, variables: { id: id } }],
  });

  // function itemUpload(url: string, data: any){
  //   const obj = JSON.parse(tag);
  //   obj['linkImagem'] = url;

  //   const newData = data;
  //   data['content'] = JSON.stringify(obj);

  //   updateFile(data)
  // }


  function updateFile(data: any) {
    variablesFile({
      variables: {
        id: id,
        data: data,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const createNewUploadFile = (item: any) => {
    const dataFile = new FormData();
    dataFile.append("file", item.files[0]);

    axios
      .post("http://localhost:3030/upload", dataFile)
      .then((res) => {
        if (res.data) {
          const url = res.data.split("?");
          const data: {} = {};
          [variable].forEach((d) => {
            data[variable] = url[0];
          });

          // tag ? itemUpload(url[0], data) : updateFile(data) 
          tag ? tag('linkImagem', url[0]) : updateFile(data)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <form className='upload_form'>
        <div><Upload_icon /></div>
        <label htmlFor='file'>
          {label ? "" : "Arraste ou <span>selecione</span> uma imagem."}
        </label>
        <input
          type='file'
          className='file'
          id='file'
          name='file'
          onChange={(e) => createNewUploadFile(e.target)}
        />
      </form>
    </>
  );
};

export default UploadFile;
