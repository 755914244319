import {  NavLink } from "react-router-dom";
import { Brand_Universe_logo, Enredo } from "../../../assets/svg";
import "./footer.scss";

export const Footer = () => {
  return (
    <footer>
      <div className='logo'>
      <NavLink to='/'>BRAND UNIVERSE</NavLink>
      </div>
      <div className='logo-enredo'>{Enredo}</div>
    </footer>
  );
};
